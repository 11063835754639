import React, { useState } from "react";
import { PageHeader } from "components/PageHeader";
import TicketShop from "components/ticket-shop";
import { Page } from "components/Page";
import { PageTitle } from "components/PageTitle";

const EventsPage = () => {
  return (
    <Page>
      <PageHeader imageSrc="events.jpg" />
      <div className="mt-8">
        <div className="container max-w-4xl mx-auto" style={{ minHeight: 400 }}>
          <TicketShop
            scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-fixr-shop-id="a3df0ec7-239b-498a-8ad2-0896a5b97904" data-fixr-theme="dark"></script>`}
          />
        </div>
      </div>
    </Page>
  );
};

export default EventsPage;
